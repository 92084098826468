const { btnObj } = require("./btnZh.js");
const { titleObj } = require("./titleZh.js");
const { formObj } = require("./formZh.js");
module.exports = {
  ...btnObj,
  ...titleObj,
  ...formObj,
  Language: "中国站",
  // 头部左边菜单
  menuList: [
    // {
    //   name: "最新活动",
    //   path: "activity",
    // },
    // {
    //   name: "产品与服务",
    //   path: "productsServices",
    // },
    {
      name: "解决方案",
      path: "solution",
    },
    // {
    //   name: "定价",
    //   path: "pricing",
    // },
    {
      name: "文档",
      path: "document",
    },
    {
      name: "了解ICDN",
      path: "understandICDN",
    },
  ],
  // 头部右边菜单
  actionsList: [
    // {
    //   name: "控制台",
    //   path: "console",
    // },
    {
      name: "登录",
      path: "login",
    },
    {
      name: "立即注册",
      path: "register",
    },
  ],
  // footer 标题
  footerTitle: {
    wideCoverage: "广泛覆盖",
    iCDN: "iCDN 电信",
    map: "网站地图",
    partners: "合作伙伴",
    contact: "联系ICDN",
  },
  wideCoverageList: ["亚洲", "东南亚", "太平洋"], // 广泛覆盖
  ICDNTelecom: ["新加坡", "马来西亚", "泰国", "印尼", "香港", "台湾", "菲律宾", "越南", "柬埔寨"], // iCDN 电信
  websiteMap: ["产品与服务", "解决方案", "定价", "文档", "了解 ICDN"], // 网站地图
  partners: [
    {
      title: "新加坡区块链技术基金会(BCF)",
      url: "https://bcf.sg/",
    },
    {
      title: "Keyword-tech 广告",
      url: "https://www.keyword-tech.com/",
    },
    {
      title: "Singapore Eye",
      url: "https://www.yan.sg/",
    },
    {
      title: "Youja视频直播",
      url: "http://www.youja.cn/",
    },
    {
      title: "北京互联网港",
      url: "https://www.bih.cn/",
    },
  ], // 合作伙伴
  // 经济、高效、稳定的云产品
  cloudProducts: {
    title: "经济、高效、稳定的云产品",
    subTitle: "生物数据处理，图像处理，视频处理，推荐算法",
    menu: [
      {
        name: "热门",
        path: "hot",
      },
      // {
      //   name: "计算",
      //   path: "compute",
      // },
      // {
      //   name: "数据库",
      //   path: "database",
      // },
      // {
      //   name: "网络",
      //   path: "networking",
      // },
      {
        name: "存储与分发",
        path: "storage",
      },
      {
        name: "海外游戏",
        path: "games",
      },
      // {
      //   name: "大数据",
      //   path: "dataAnalysis",
      // },
      // {
      //   name: "视频服务",
      //   path: "videoServices",
      // },
      {
        name: "人工智能",
        path: "artificialIntelligence",
      },
      // {
      //   name: "企业服务",
      //   path: "enterpriseServices",
      // },
    ],
    hot: {
      theme: {
        choose: {
          title: "海外直播节点选购",
          describe: "企业新用户首购0元起",
        },
        server: {
          title: "海外节点服务器",
          describe: "适用于国外网络加速及游戏加速",
          ARM: {
            title: "ARM",
            config: "配置:8核 8G",
            bandwidth: "带宽:100-300M",
            systemDisk: "系统盘:256G高效M.2",
          },
          Serx86: {
            title: "Serx86",
            config: "配置:16核 16G",
            bandwidth: "带宽:300M-500M",
            systemDisk: "系统盘:1T高效云盘",
          },
        },
        KEC: {
          title: "海外云服务KEC",
          describe: "适用于计算及网络需求均衡的场景，为企业客户提供稳定计算能力",
          config: "配置:2核4G",
          bandwidth: "带宽:1-5M",
          systemDisk: "系统盘:50G高效云盘",
          tips: "只针对于企业用户",
        },
      },
      basicType: {
        title: "ARM基础型",
        describe: "云服务器",
        config: "配置：4核 1.5",
        memory: "内存：2G",
        bandwidth: "带宽：100M",
        storage: "存储：128G",
      },
      advancedType: {
        title: "ARM进阶型",
        describe: "云服务器",
        config: "配置：4核 2.0",
        memory: "内存：4G",
        bandwidth: "带宽：200M",
        storage: "存储：256-512G",
      },
      upscale: {
        title: "X86高端",
        describe: "云服务器",
        config: "配置：36核 2.6",
        memory: "内存：16G",
        bandwidth: "带宽：300M",
        storage: "存储：4T",
      },
    },
    storage: {
      title: "存储与分发",
      describe: "有效地管理和优化读缓存和写缓存，这有助于提升系统响应速度。可以自动地进行分级存储，以适应不同热度的数据",
      list: [
        {
          title: "支持分级存储",
          describe: "这种存储方式允许高速存储和低速存储独立部署，可以根据实际需要进行灵活调整，避免了因数据量过大导致的性能问题。",
        },
        {
          title: "多副本的一致性",
          describe:
            "与传统的RAID模式相比，分布式存储使用了多副本备份机制。这样能确保数据的高可用性和可靠性，即使某一副本出现问题，也可以通过其他副本进行恢复。 容灾与备份能力：它能提供多时间点的快照技术，方便用户在遇到特定情况时进行数据恢复。",
        },
        {
          title: "扩展性强",
          describe: "它可以通过增加新的节点来进行扩展，无需停止当前的工作流程，可以轻松地对新加入的节点进行配置和管理",
        },
      ],
    },
    games: {
      title: "海外游戏",
      describe:
        "CDN构建于ICDN Pro全球边缘分布式icdn平台之上，可以为用户提供可编程式CDN交互服务。通过CDN Pro，用户可以快速构建个性化业务体系，将业务应用下沉至边缘侧，以降低业务开发交付周期，实现CDN与软件业务开发生命周期的高效无缝衔接。合作公司及团体需要国内合规。",
    },
    artificialIntelligence: {
      title: "人工智能",
      describe:
        "AI算力调度平台是一个面向AI算力基础设施的解决方案，可实现多种算力平台统一融合和管理，并能根据不同的应用、模型和资源需求，在多个AI算力资源池之间进行灵活的部署和调度。通过AI算力调度平台，可以实现AI算力的服务化、场景化、自动化，从而加速AI算力资源的商业化进程，提升AI算力的使用率和便捷性。",
    },
  },
  // 各大行业全栈解决方案
  solution: {
    navList: [
      {
        name: "游戏",
        path: "game",
      },
      {
        name: "WEB 3.0",
        path: "web",
      },
      {
        name: "海外直播带货",
        path: "liveBroadcast",
      },
      {
        name: "AI",
        path: "ai",
      },
      {
        name: "视频",
        path: "videoView",
      },
    ],
    games: {
      title: "海外游戏加速",
      describe:
        "海外游戏加速方案通过使用CDN技术，将游戏内容分布在不同地理位置的服务器上，以实现更快的访问速度。该方案可以加速海外游戏的访问速度、增强用户体验、减少服务器压力、提高网站安全性、支持搜索引擎优化以及支持大文件资源加载等优点。通过使用CDN海外游戏加速方案，可以有效提高游戏的性能和稳定性，为用户提供更加优质的游戏体验。",
    },
    web: {
      title: "WEB 3.0",
      describe:
        "WEB 3.0解决方案基于区块链技术，致力于构建更安全、透明和去中心化的互联网。该方案通过智能合约、去中心化存储和分布式身份认证等技术，实现数据自主权和隐私保护，同时提高数据安全性和可信度。WEB 3.0解决方案还注重用户参与和社区建设，通过DAO（去中心化自治组织）等模式，让用户拥有更多的自主权和收益权，推动互联网向更加公平、开放和自由的方向发展。",
    },
    liveBroadcast: {
      title: "海外直播带货",
      describe:
        "通过使用CDN技术，为海外直播带货提供稳定、高效、安全的加速服务。该方案可以将直播内容快速分发到全球各地的CDN节点服务器，以实现低延迟、流畅的直播体验。同时，该方案还可以提供多种安全防护措施，如防DDoS攻击、防盗链等，保障直播带货的安全和稳定。此外，该方案还支持多种直播格式和协议，可以满足不同用户的需求。",
    },
    ai: {
      title: "AI人工智能",
      describe:
        "AI算力调度平台是一个面向AI算力基础设施的解决方案，可实现多种算力平台统一融合和管理，并能根据不同的应用、模型和资源需求，在多个AI算力资源池之间进行灵活的部署和调度。通过AI算力调度平台，可以实现AI算力的服务化、场景化、自动化，从而加速AI算力资源的商业化进程，提升AI算力的使用率和便捷性。",
    },
    videoView: {
      title: "视频加速",
      describe:
        "CDN视频加速解决方案利用CDN技术，将视频内容分发至靠近用户的节点服务器，以提升视频播放的流畅度和响应速度。此方案可减轻源站压力，保障稳定性，同时提供安全防护。用户可快速、稳定地获取视频内容，提升观看体验。CDN服务商提供灵活的计费方式和优化的用户体验，满足各种视频应用场景的需求。",
    },
  },
  // 解决方案页面
  solutionIndex: {
    title: "iCDN Prime",
    subTitle: "iCDN Prime与传统CDN兼容。提供高的视频QoS。<br />我们先进的多点群传输(MPGT)云在移动设备和网络上更友好，<br />具有高CDN功能，成本超低。",
    primeList: [
      {
        title: "精益数据流量控制p2p和CDN",
        describe: "基于先进的多点组传输(MPGT)机制，实现P2P和CDN之间的无缝传输。很好地平衡了视频数据传输成本和QoS。",
      },
      {
        title: "内容保护",
        describe: "多种内容安全方案:DRM、端到端分片加密、分布式存储。",
      },
      {
        title: "智能节点",
        describe: "多点群传输(MPGT)方案根据用户位置和文件流行度选择最佳节点。",
      },
      {
        title: "移动SDK具有良好的性能",
        describe: "CPU占用率低，能耗低，兼容性测试大，适合复杂的网络环境。",
      },
      {
        title: "弱网络智能路由",
        describe: "数据驱动多点群传输(MPGT)系统在弱网络环境下提供了良好的服务质量。",
      },
      {
        title: "强大的网络直通技术",
        describe: "该系统支持uPnP和NAT，使得超过70%的内部网络流量能够顺畅传输。",
      },
    ],
    flowObj: {
      title: "MPGT专用视频CDN精益数据流量控制",
      describe: "iCDN Prime采用精益数据流量控制技术，实现了在CDN节点和MPGT节点之间的智能转换。它通过降低CDN的峰值流量，在保证最佳传输/下载速度的同时，提供了均衡且节省成本的QoS和MPGT比率。",
    },
    systemObj: {
      title: "先进多点群传输(mpgt)系统",
      describe: "iCDN Prime使用机器学习智能搜索最佳节点，分析和优化端与端之间的连接用户和节点，平均跳数从5.5减少到1.1，从而减少骨干网的使用，性能提高5倍。",
    },
    routeObj: {
      title: "弱网络下的智能路由",
      describe: "高级MPGT (multipoint group transfer)是一种专门用于提高视频流场景下多节点流下载的QoS和减少不稳定性的技术。采用数据驱动和预分析技术，实现最大的下载速度和最小的冗余。",
    },
    QOSObj: {
      title: "完美的QOS",
      describe: "得益于我们10多年的密集流媒体数据传输经验，我们推出iCDN Prime作为专门为VOD提供的产品，以提高他们的QoS。",
    },
    nodeObj: {
      title: "全球在线节点覆盖",
      subTitle: "超过100万个节点设备的大规模覆盖，提供了灵活的能力供应。",
    },
  },
  // 了解ICDN
  understandICDN: {
    spirit: {
      title: "企业精神",
      list: [
        {
          title: "综合能力",
          describe: `
            <p>我们是一个由来自不同行业的成功企业家和工程师组成的团队。</p>
            <p>我们的能力整合了所有需要的因素，成为一个值得信赖的专用视频CDN解决方案供应商。</p>
          `,
        },
        {
          title: "成本效益",
          describe: `
            <p>iCDN的各种<span>专用视频CDN解决方案</span></p>
            <p>视频网站将免收天价视频CDN话费。</p>
            <p>下载网站将免除庞大数据下载流量的高额费用。</p>
          `,
        },
        {
          title: "核心技术",
          describe: `
            <p>我们开发和利用多点群传输(MPGT)技术来提高性能和安全性，降低数据传输成本。</p>
          `,
        },
      ],
    },
  },

  // 文档
  document: {
    navList: [
      {
        name: "边缘节点数据传输",
        path: "dataTransmission",
      },
      // {
      //   name: "Edge节点计算",
      //   path: "count",
      // },
      // {
      //   name: "Edge节点存储",
      //   path: "storage",
      // },
    ],
    dataTransmission: [
      {
        title: "智能化运行，利用率提高15%",
        describe:
          "传统的DevOps解决方案已经被智能地转变了。采用改进的Boosting模型，通过云与边缘的智能协同，90%以上的运营问题实现了自动化处理，显著提高了边缘计算场景下的产品交付质量和运营效率，边缘节点的资源利用率提高了15%。",
        img: "",
      },
      {
        title: "毫秒级低延迟，延迟<30ms",
        describe: "优质节点部署在用户终端周围，覆盖全国各县市及各大运营商。业务内容分布到全网的加速节点，解决网络拥塞问题。这有效地提高了访问成功率和响应速度，将传输成本降低了50%，延迟低至毫秒级。",
        img: "",
      },
      {
        title: "最高的效率，调度率提高了35%",
        describe: "Edge是基于主流的非标准异构边缘计算网络容器引擎K8S设计的。通过使用标准和开放的云原生技术和功能，实现边缘容器编排，将资源调度效率提高35%，实现最终的云机器效率。",
        img: "",
      },
      {
        title: "全网分布，极致用户体验",
        describe: "支持全国所有地级市、多种运营商网络环境、WEB / iOS / Android等多平台，提供全网分布式云服务能力，处理高并发，保证接入质量。",
        img: "",
      },
      {
        title: "",
        describe: "",
        img: require("@/assets/images/document_img_01.png"),
      },
      {
        title: "7x24小时技术支持服务",
        describe: "全环节全面的实时数据检测系统，7x24小时技术支持服务，快速响应并解决在线问题。",
        img: "",
      },
    ],
  },
  // ICDN动态
  news: {
    list: [
      {
        title: "全球云加速器秒级避堵，打造全球云高速畅通网络!",
        time: "2023-08-01",
        id: "0",
        contents: "",
      },
      {
        title: "海外直播间，助力电商直播带货变现",
        time: "2023-08-01",
        id: "1",
        contents: "",
      },
      {
        title: "第七代云服务开启邀测",
        time: "2023-08-01",
        id: "2",
        contents: "",
      },
      {
        title: "全球骨干网和ARM集群服务器，帮助您快速布局云游",
        time: "2023-08-01",
        id: "3",
        contents: "",
      },
      {
        title: "海外机房节点，直通美国、英国、印尼，支持多国多",
        time: "2023-08-01",
        id: "4",
        contents: "",
      },
      {
        title: "全球骨干网和ARM集群服务器，帮助您快速",
        time: "2023-08-01",
        id: "5",
        contents: "",
      },
      {
        title: "iCDN：视频网站实现海外 降本增效的“一剂良药”",
        time: "2023-08-01",
        id: "6",
        contents: `2005年到2014年，国内视频网站经历反盗版、会员付费、自制内容等多个阶段后，“优爱腾”三足鼎立的市场局面逐渐清晰，用户增速持续下滑，完成了从增量市场到存量市场的过渡。有数据显示，2018年网络视频整体用户增速降至5.7%，而2008年这一数字是25.5%。
除了视频网站增速下滑外，国内大量互联网产品也已触及增长天花板，新产品更是屡屡夭折，无论存量市场还是增量市场都是混战一片，寒冬之中，无人得以幸免。
因此，为了满足自身扩张和对新增量的渴望，走向海外寻找新市场几乎是各家平台同时作出的决定。
2019年，爱奇艺推出了海外版，如今每个月观看其视频的海外用户已达数千万；优酷出海的内容也覆盖了193个国家和地区的用户；《2020腾讯视频年度指数报告》显示，WeTV用户数较上一年增长175%+，观看总时长增长300%+，用户覆盖110余个国家和地区。
在头部视频网站出海已成趋势的背景下，中小视频网站也进一步加快了步伐。但不论是“爱优腾”还是“中小微”，在出海淘金的过程中都不得不面对一个痛点——CDN问题。
对于许多视频网站来说，CDN太贵了，仅次于影视剧版权费用。如今，在4K乃至8K视频和5G的刺激下，CDN成本更是指数倍上涨，许多视频平台都出现了无法承担高昂的CDN成本的窘境。
好在经过一步步的探索和磨合后，新一代分布式数据缓存架构平台 PCDN应运而生。而iCDN建立了海外最大的PCDN节点集群。
据了解，iCDN采用低成本边缘异构光纤宽带建立起机器集群的方法，用PPIO开发的集群管理软件进行管理各个节点，运行PPIO、爱奇艺等PCDN节点，实现高性价比的数据传输和缓存能力，进而降低成本。同时，iCDN分布式的节点还有可以解决中心节点的稳定性低、易被制裁等问题。
几年间，iCDN已经服务于多个出海的大中小视频网站，为它们节省了50%-70%左右的带宽成本。可以说，iCDN的出现已经成为了视频网站实现海外降本增效的一剂良药。
今日，希鸥网记者有幸采访到了iCDN Telecom创始人朱红兵，更多精彩内容请看采访实录。`,
      },
    ],
  },
  newDetail: {
    title: "iCDN：视频网站实现海外 降本增效的“一剂良药”——专访iCDN Telecom创始人朱红兵",
    time: "2022-08-29",
    content: `
      <p>
        2005年到2014年，国内视频网站经历反盗版、会员付费、自制内容等多个阶段后，“优爱腾”三足鼎立的市场局面逐渐清晰，用户增速持续下滑，完成了从增量市场到存量市场的过渡。有数据显示，2018年网络视频整体用户增速降至5.7%，而2008年这一数字是25.5%。
      </p>
      <p>
        除了视频网站增速下滑外，国内大量互联网产品也已触及增长天花板，新产品更是屡屡夭折，无论存量市场还是增量市场都是混战一片，寒冬之中，无人得以幸免。
      </p>
      <p>
        因此，为了满足自身扩张和对新增量的渴望，走向海外寻找新市场几乎是各家平台同时作出的决定。
      </p>
      <p>
        2019年，爱奇艺推出了海外版，如今每个月观看其视频的海外用户已达数千万；优酷出海的内容也覆盖了193个国家和地区的用户；《2020腾讯视频年度指数报告》显示，WeTV用户数较上一年增长175%+，观看总时长增长300%+，用户覆盖110余个国家和地区。
      </p>
      <p>
        在头部视频网站出海已成趋势的背景下，中小视频网站也进一步加快了步伐。但不论是“爱优腾”还是“中小微”，在出海淘金的过程中都不得不面对一个痛点——CDN问题。
      </p>
      <p>
        对于许多视频网站来说，CDN太贵了，仅次于影视剧版权费用。如今，在4K乃至8K视频和5G的刺激下，CDN成本更是指数倍上涨，许多视频平台都出现了无法承担高昂的CDN成本的窘境。
      </p>
      <p>
        好在经过一步步的探索和磨合后，新一代分布式数据缓存架构平台 PCDN应运而生。而iCDN建立了海外最大的PCDN节点集群。
      </p>
      <p>
        据了解，iCDN采用低成本边缘异构光纤宽带建立起机器集群的方法，用PPIO开发的集群管理软件进行管理各个节点，运行PPIO、爱奇艺等PCDN节点，实现高性价比的数据传输和缓存能力，进而降低成本。同时，iCDN分布式的节点还有可以解决中心节点的稳定性低、易被制裁等问题。
      </p>
      <p>
        几年间，iCDN已经服务于多个出海的大中小视频网站，为它们节省了50%-70%左右的带宽成本。可以说，iCDN的出现已经成为了视频网站实现海外降本增效的一剂良药。
      </p>
      <p>
        今日，希鸥网记者有幸采访到了iCDN Telecom创始人朱红兵，更多精彩内容请看采访实录。
      </p>
      <p class="display-flex ">
        <img src="${require("@/assets/images/news_details_img_04.png")}"  />
       
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;">希鸥网：朱总您好，请您介绍一下您自己，包括您的学习、工作经历。</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;">朱红兵：</lable>希鸥网的读者们大家好，我是iCDN Telecom的创始人朱红兵。1996年，我毕业于哈尔滨工业大学机电学院，刚毕业时在北京汉王科技从事OCR衍生产品“汉王名片通”的研发工作。2000年，我来到了新加坡，并且加入了互联网域名公司i-DNS.net负责多语言域名系统的研发。
      </p>
      <p>
        我从2003年就开始尝试创业了，十年里先后创立了集思科技和关键字科技。集思科技专注于机器翻译和教育产品的研发，关键字科技则专注于海外华人人群广告的发布。同时我也参与过一些天使投资，为一些对可能对社会有帮助的公司提供了资金支持。
      </p>
      <p>
        2019年年底，我创立了iCDN Telecom，主要做边缘云异构节点，专注于在海外为视频公司和AI公司提供廉价海量的云传输和云算力。目前为止，我们已经为多家视频公司提供了近400G的海外传输带宽。
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;">希鸥网：您的工作经历其实非常丰富，当过技术员、创办过互联网科技公司也搞过创投，最终是什么样的契机让您选择了进入云传输这个领域？</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 朱红兵：</lable>之所以进入云传输领域，主要还是看到了合适切入点和商业空间。
      </p>
      <p>
          2019年，许多视频网站想要出海“淘金”，但是海外的CDN基本被欧美大公司垄断，比如Akamai、CloudFront、Google等，不仅价格高昂，而且很容易因为各种原因被制裁。
      </p>
      <p>
        我刚好了解到了他们出海的需求以及他们所面临的痛点，在夹缝中寻找到了开始的机会，经过调研，就迈入了云传输领域，为几个有出海需求的视频企业在东南亚提供带宽和缓存节点的服务，然后在这个业务里找到了可以扩充到其他有相似性需求企业的一般性解决服务方案，并提供给了区域内更多的企业。
      </p>
       <p>
        <lable style=" color: #333; font-weight: bold;"> 希鸥网：现阶段，iCDN Telecom能够解决大中小视频网站的什么痛点？主要被运用在什么场景？</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 朱红兵：</lable>首先是价格痛点。视频行业最大的成本就在于视频传输，在高清晰视频越来越普及之后，视频传输成本更成为了行业内不断增长的压力。对于爱奇艺、优酷这样的大网站来说，传输成本占整个公司的开支大约15%~22%；对于其他中小视频网站来说，传输成本占更是高达整个公司开支的50%以上。而我们因为使用小企业机房的异构资源，通常可以将成本压缩至一半左右，极大减轻了视频网站的运行成本。
      </p>
      <p>
        其次，稳定性痛点。我们是分布式的节点，整体稳定性更好，并且因为节点分布在全球各地，即使一个地方的网络受到了电力供应或主干网络状态的影响，也可以在秒级把其他地方的节点资源调度过来，保证了视频网站的平稳运行。
      </p>
      <p>
        最后，制裁痛点。我们绝大部分节点都放在小微企业的机房里面，不会受到国际大环境的影响，防止客户因为受到制裁而影响海外业务。我们本质是CDN业务，为视频公司提供加速节点，并与客户原有的CDN组成廉价、稳定、强大的视频传输能力。
      </p>
      <p class="display-flex ">
        <img src="${require("@/assets/images/news_details_img_03.png")}"  />
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 希鸥网：iCDN base 和iCDN plus两个版本之间有什么样的区别？</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 朱红兵：</lable>两者的目标受众不同。
      </p>
      <p>
        iCDN base 主要提供给大视频公司。他们使用的仍然是自己的视频传输和节点调度软件，我们只提供比较基础的IaaS服务。原因是大公司技术力量强，有能力开发自己专用的视频传输和节点调度软件，而我们则为了保障其流畅运行，起到了辅助作用。iCDN base 一般提供100G以上的带宽，可以有效降低 30-50% 的服务器和带宽成本。
      </p>
      <p>
        iCDN Plus 主要提供给中小型视频公司，他们使用的是我们自己开发的视频传输和节点调度软件，我们提供的是更高一层的PaaS服务。小公司一般自己没有技术力量开发视频传输和节点调度软件，所以使用我们的302跳转或者SDK集成到他们的APP里，完成高效视频传输。iCDN plus一般提供10~100G的带宽，可以有效降低 30%~75% 左右的服务器带宽成本。
      </p>
       <p>
        <lable style=" color: #333; font-weight: bold;"> 希鸥网：与同行相比，iCDN Telecom具有什么样的核心竞争力/差异化优势？</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 朱红兵：</lable>首先，相较其他同行我们出海比较早，经验更为丰富，各个国家的边缘异构节点我们都会定期做全面的调查和测试，价格、实际速度、稳定性等问题了如指掌。
      </p>
      <p>
        其次，我们节点的部署和维护能力都很强，尤其是在东南亚国家，我们拥有自建的节点，控制能力强、全端口开放，每个月可以开拓和部署超过百Gb级别的分布式缓存带宽节点。
      </p>
      <p>
         同时，我们能够根据视频网站的规模和具体要求提供整体、可行的技术方案和实施方案，有效帮助客户降低不必要的成本。
      </p>
      <p>
        最后，在服务方面，我们提供7*24小时的全时段服务，帮助客户解决各种大小问题，让客户可以高枕无忧。
      </p>
       <p>
        <lable style=" color: #333; font-weight: bold;"> 希鸥网：在您看来，云传输领域的发展格局未来会有哪些新的变化？</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 朱红兵：</lable>随着Web3.0的兴起，可以预见的是，会有一些Web3.0公司通过补贴进入这个领域，而当市场的份额有所提升时，成本将会进一步变低。我们也会成为Web3系统的节点提供商，等到时间窗口合适的时候，我们也会做更深层的尝试，帮助客户企业更好地发展。
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 希鸥网：请您谈一下iCDN Telecom未来的规划以及您对iCDN Telecom的期待。</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> 朱红兵：</lable>云传输方面，近期我们还是把主要精力放在节点的发展上，尤其是在东南亚和日韩台扩充节点，加深服务能力。我们想要利用现有的大公司的业务需求，用租赁机器的方式，在拥有低成本大带宽的地点建立起来家庭和中小企业供给端节点，并且持续降低价格，增加整体稳定性，满足大客户的定制化需求，不断满足客户在海外持续增长的视频缓存和传输的需求。
      </p>
      <p>
        另外，我们会持续在云算力方向上发力，为生物医药企业提供海量的深度学习廉价算力，以助力药物的研发为切入点，开发新的廉价算力的应用场景。
      </p>
    `,
  },
};
