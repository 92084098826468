import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "@/styles/reset.scss";
import "element-ui/lib/theme-chalk/display.css";
import "@/utils/animation.js";
import "@/utils/common.js";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./locals";
Vue.use(ElementUI);

// import VueAMap from "vue-amap";

// Vue.use(VueAMap);
// // 初始化vue-amap 开始
// VueAMap.initAMapApiLoader({
//   key: "970d533bda30e6afc89da9cd015c63c7",
//   //插件根据自己项目中需要用到的自行添加
//   plugin: ["AMap.Scale", "AMap.OverView", "AMap.ToolBar", "AMap.MapType", "AMap.PlaceSearch", "AMap.Geolocation", "AMap.Geocoder", "AMap.DistrictSearch", "AMap.Polygon"],
//   // plugin: ["AMap.Polygon"],
//   v: "1.4.4",
//   uiVersion: "1.0", // 版本号
// });
// window._AMapSecurityConfig = {
//   securityJsCode: "92ce11e1ee83507508a1b62173ffc478	",
// };
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
