import Vue from "vue";
import VueI18n from "vue-i18n";
import ElementLocal from "element-ui/lib/locale";
import enLocale from "element-ui/lib/locale/lang/en"; //引入elementui语言包
import zhLocale from "element-ui/lib/locale/lang/zh-CN"; //引入ele语言包
const cn = require("./zh"); //中文对应
const en = require("./en"); //英文对应
Vue.use(VueI18n);
//使用的是sessionStroage中存储的的lang，默认是中文简体cn
var language = window.sessionStorage.getItem("lang") || "cn";
console.log(language);
Vue.prototype.$language = language;
const i18n = new VueI18n({
  locale: language, //将i18n的locale设置为你需要的语言
  messages: {
    cn: {
      ...zhLocale,
      ...cn,
    },
    en: { ...enLocale, ...en },
  },
  silentFallbackWarn: true,
});
ElementLocal.i18n((key, value) => i18n.t(key, value));
export default i18n;
