const { btnObj } = require("./btnEN.js");
const { titleObj } = require("./titleEN.js");
const { formObj } = require("./formEN.js");
module.exports = {
  ...btnObj,
  ...titleObj,
  ...formObj,
  Language: "Malaysian",
  // 头部左边菜单
  menuList: [
    // {
    //   name: "Latest Activities",
    //   path: "activity",
    // },
    // {
    //   name: "Products",
    //   path: "productsServices",
    // },
    {
      name: "Solution",
      path: "solution",
    },
    // {
    //   name: "Pricing",
    //   path: "pricing",
    // },
    {
      name: "Documentation",
      path: "document",
    },
    {
      name: "ICDN",
      path: "understandICDN",
    },
  ],
  // 头部右边菜单
  actionsList: [
    // {
    //   name: "Console",
    //   path: "console",
    // },
    {
      name: "Log In",
      path: "login",
    },
    {
      name: "Register",
      path: "register",
    },
  ],
  // footer 标题
  footerTitle: {
    wideCoverage: "Wide Coverage",
    iCDN: "ICDN Telecom",
    map: "Website Map",
    partners: "Partners",
    contact: "Contact ICDN",
  },
  wideCoverageList: ["Asia", "Southeast Asia", "Pacific"], // 广泛覆盖
  ICDNTelecom: ["Singapore", "Malaysia", "Thailand", "Indonesia", "Hong Kong", "Taiwan", "Philippines", "Vietnam", "Cambodia"], // iCDN 电信
  websiteMap: ["Products", "Solution", "Pricing", "Documentation", "ICDN"], // 网站地图
  partners: [
    {
      title: "Singapore Blockchain Technology Foundation(BCF)",
      url: "https://bcf.sg/",
    },
    {
      title: "Keyword-tech advertisement",
      url: "https://www.keyword-tech.com/",
    },
    {
      title: "Singapore Eye",
      url: "https://www.yan.sg/",
    },
    {
      title: "Youja Video Live",
      url: "http://www.youja.cn/",
    },
    {
      title: "Beijing Internet Port",
      url: "https://www.bih.cn/",
    },
  ],

  // 经济、高效、稳定的云产品
  cloudProducts: {
    title: "Economical, efficient, and stable cloud products",
    subTitle: "Biological data processing, image processing, video processing, recommendation algorithms",
    menu: [
      {
        name: "Hot",
        path: "hot",
      },
      // {
      //   name: "Compute",
      //   path: "compute",
      // },
      // {
      //   name: "Database",
      //   path: "database",
      // },
      // {
      //   name: "Networking",
      //   path: "networking",
      // },
      {
        name: "Storage & Delivery",
        path: "storage",
      },
      {
        name: "Game",
        path: "games",
      },
      // {
      //   name: "Data Analysis",
      //   path: "dataAnalysis",
      // },
      // {
      //   name: "Video Services",
      //   path: "videoServices",
      // },
      {
        name: "Ai",
        path: "artificialIntelligence",
      },
      // {
      //   name: "Enterprise Services",
      //   path: "enterpriseServices",
      // },
    ],
    hot: {
      theme: {
        choose: {
          title: "Live streaming node selection",
          describe: "Starting from 0 yuan for the first purchase of new enterprise users",
        },
        server: {
          title: "Overseas node servers",
          describe: "Suitable for overseas network acceleration and game acceleration",
          ARM: {
            title: "ARM",
            config: "Configuration: 8-core 8G",
            bandwidth: "Bandwidth: 100-300M",
            systemDisk: "System disk: 256G efficient M.2",
          },
          Serx86: {
            title: "Serx86",
            config: "Configuration: 16-cores 16G",
            bandwidth: "Bandwidth: 300M-500M",
            systemDisk: "System disk: 1T efficient cloud disk",
          },
        },
        KEC: {
          title: "Cloud Service KEC",
          describe: "Suitable for scenarios with balanced computing and network demands, providing stable computing power for enterprise customers",
          config: "Configuration: 2-core 4G",
          bandwidth: "Bandwidth: 1-5M",
          systemDisk: "System disk: 50G efficient cloud disk",
          tips: "Only for enterprise users",
        },
      },
      basicType: {
        title: "ARM Basic",
        describe: "Cloud server",
        config: "Configuration: 4-core 1.5",
        memory: "Memory: 2GB",
        bandwidth: "Bandwidth: 100M",
        storage: "Storage: 128G",
      },
      advancedType: {
        title: "ARM Advanced",
        describe: "Cloud server",
        config: "Configuration: 4-core 2.0",
        memory: "Memory: 4G",
        bandwidth: "Bandwidth: 200M",
        storage: "Storage: 256-512G",
      },
      upscale: {
        title: "X86 High-End",
        describe: "Cloud server",
        config: "Configuration: 36-cores 2.6",
        memory: "Memory: 16GB",
        bandwidth: "Bandwidth: 300M",
        storage: "Storage: 4T",
      },
    },
    storage: {
      title: "Storage and Distribution",
      describe:
        "Effectively managing and optimizing read and write cache can help improve system response speed. It can automatically perform hierarchical storage to adapt to data of different heat levels.",
      list: [
        {
          title: "Support hierarchical storage",
          describe:
            "This storage method allows for independent deployment of high-speed and low-speed storage, and can be flexibly adjusted according to actual needs, avoiding performance issues caused by excessive data volume.",
        },
        {
          title: "Consistency of multiple replicas",
          describe:
            "Compared to traditional RAID mode, distributed storage uses a multi copy backup mechanism for consistency in multiple copies. This ensures high availability and reliability of data, and even if one replica encounters a problem, it can be restored through other replicas. Disaster recovery and backup capabilities: It can provide multi point in time snapshot technology, making it convenient for users to recover data in specific situations.",
        },
        {
          title: "Strong scalability",
          describe: "It can be expanded by adding new nodes without stopping the current workflow, making it easy to configure and manage newly added nodes.",
        },
      ],
    },
    games: {
      title: "Overseas games",
      describe:
        "CDN is built on the ICDN Pro global edge distributed icdn platform and can provide users with programmable CDN interaction services. Through CDN Pro, users can quickly build personalized business systems, sink business applications to the edge, reduce business development delivery cycles, and achieve efficient and seamless integration between CDN and software business development lifecycle. Cooperating companies and groups require domestic compliance.",
    },
    artificialIntelligence: {
      title: "artificial intelligence",
      describe:
        "The AI computing power scheduling platform is a solution for AI computing infrastructure, which can achieve unified integration and management of multiple computing power platforms, and can flexibly deploy and schedule between multiple AI computing power resource pools according to different applications, models, and resource requirements. Through the AI computing power scheduling platform, the service, scenario, and automation of AI computing power can be achieved, thereby accelerating the commercialization process of AI computing resources and improving the utilization and convenience of AI computing power.",
    },
  },
  // 各大行业全栈解决方案
  solution: {
    navList: [
      {
        name: "Game",
        path: "game",
      },
      {
        name: "WEB 3.0",
        path: "web",
      },
      {
        name: "Live Broadcast",
        path: "liveBroadcast",
      },
      {
        name: "AI",
        path: "ai",
      },
      {
        name: "Video",
        path: "videoView",
      },
    ],
    games: {
      title: "Overseas game acceleration",
      describe:
        "The game acceleration solution utilizes CDN technology to distribute game content across servers in different geographical locations, achieving faster access speeds. This solution can accelerate game access speed, enhance user experience, reduce server pressure, improve website security, and support loading of large file resources.",
    },
    web: {
      title: "WEB 3.0",
      describe:
        "The WEB 3.0 solution is based on blockchain technology and is committed to building a more secure, transparent, and decentralized internet. This scheme achieves data autonomy and privacy protection through technologies such as smart contracts, decentralized storage, and distributed identity authentication, while improving data security and credibility.",
    },
    liveBroadcast: {
      title: "Overseas live streaming sales",
      describe:
        "By using CDN technology, we provide stable, efficient, and secure acceleration services for overseas live streaming sales. This solution can quickly distribute live content to CDN node servers around the world, achieving a low latency and smooth live streaming experience. This plan also provides multiple security measures to ensure safety and stability.",
    },
    ai: {
      title: "Artificial Intelligence",
      describe:
        "The AI computing power scheduling platform is a solution for AI computing infrastructure, which can achieve unified integration and management of multiple computing power platforms, and can flexibly deploy and schedule between multiple AI computing power resource pools according to different applications, models, and resource requirements.",
    },
    videoView: {
      title: "Video acceleration",
      describe:
        "The CDN video acceleration solution utilizes CDN technology to distribute video content to node servers close to users, in order to improve the smoothness and response speed of video playback. This plan can reduce the pressure on the source station, ensure stability, and provide safety protection.",
    },
  },
  // 解决方案页面
  solutionIndex: {
    title: "iCDN Prime",
    subTitle:
      "iCDN Prime is compatible with traditional CDN. It provides high video QoS.<br />Our advanced multipoint group transfer (MPGT) cloud works more friendly on <br />mobile device and web with high CDN capability at super low cost.",
    primeList: [
      {
        title: "LEAN DATA TRAFFIC CONTROL P2P AND CDN",
        describe: "Based on advanced multipoint group transfer (MPGT) mechanism, seamless shift between P2P and CDN. Well balance between video data transfer cost and QoS",
      },
      {
        title: "CONTENT PROTECTION",
        describe: "Multiple content security schemes: DRM, end-to-end shard encryption distributed storage",
      },
      {
        title: "SMART NODES",
        describe: "Multipoint group transfer (MPGT) scheme chooses the best nodes according to user location and file popularity",
      },
      {
        title: "MOBILE SDK PERFORMANCE FRIENDLY",
        describe: "Low CPU usage, low enery usage, large compability testing, caters to complicated network environment.",
      },
      {
        title: "WEAK NETWORK SMART ROUTING",
        describe: "Data driven multipoint group transfer (MPGT) system offers excellent QoS in Weak network",
      },
      {
        title: "POWERFUL NETWORK PASS-THROUGH TECHNOLOGY",
        describe: "Support uPnP and NAT, and more than 70% pass-through rate from internal networkSupport uPnP and NAT, and more than 70% pass-through rate from internal network",
      },
    ],
    flowObj: {
      title: "LEAN DATA TRAFFIC CONTROL OF MPGT PRIVATE VIDEO CDN",
      describe:
        "iCDN Prime’ lean data traffic control technolgy allows a smart shift amongst CDN nodes and MPGT nodes. It offers a balanced and cost-saving QoS and MPGT ratio by reducing the peak traffic of CDN with the best transfer/download speed guaranteed at the same time.",
    },
    systemObj: {
      title: "ADVANCED MULTIPOINT GROUP TRANSFER (MPGT) SYSTEM",
      describe:
        "iCDN Prime smartly searches the best nodes using machine learning to analyze and optimize the connections between end users and nodes, reducing hop from 5.5 to 1.1 by average, and as a result reduces the usage of backbone network and improving the performance by 5 times.",
    },
    routeObj: {
      title: "Intelligent Routing in Weak NetworksSMART ROUTING ON WEAK NETWORK",
      describe:
        "Advanced MPGT (multipoint group transfer) is specialized in imporving QoS and reducing instability of multi-node streaming downloading in video streaming scenarios. Data driven and pre-anylising technology are applied to acheive maximum download speed and minimum redundancy.",
    },
    QOSObj: {
      title: "PERFECT QOS",
      describe: "Benefiting from our 10+ years of intensive streaming data transfering experience, we present iCDN Prime as a product specialized for VOD to imporve their QoS.",
    },
    nodeObj: {
      title: "WORLDWIDE ONLINE NODES COVERAGE",
      subTitle: "Large-scale device coverage with more than 1 million <br/>nodes provides flexible capability supply.",
    },
  },
  // 了解ICDN
  understandICDN: {
    spirit: {
      title: "Enterprise Spirit",
      list: [
        {
          title: "Comprehensive ability",
          describe: `
            <p>We are a team composed of successful entrepreneurs and engineers from different industries.</p>
            <p>Our capabilities integrate all the necessary factors to become a trusted provider of dedicated video CDN solutions.</p>
          `,
        },
        {
          title: "Cost effectiveness",
          describe: `
            <p>Various aspects of iCDN<span>Dedicated Video CDN Solution</span></p>
            <p>Video websites will be exempt from sky high video CDN call fees.</p>
            <p>Downloading websites will waive the high cost of large data download traffic.</p>
          `,
        },
        {
          title: "core technology",
          describe: `
            <p>We develop and utilize Multipoint Group Transmission (MPGT) technology to improve performance and security, and reduce data transmission costs.</p>
          `,
        },
      ],
    },
  },
  // 文档
  document: {
    navList: [
      {
        name: "Edge-node Data Transfer",
        path: "dataTransmission",
      },
      // {
      //   name: "Edge-node Computing",
      //   path: "count",
      // },
      // {
      //   name: "Edge-node Storage",
      //   path: "storage",
      // },
    ],
    dataTransmission: [
      {
        title: "Intelligent operation, with a 15% increase in utilization rate",
        describe:
          "The traditional DevOps solution has been intelligently transformed. Using the improved Boosting model, and through intelligent collaboration between cloud and edge, over 90% of operational issues are automatically handled, significantly improving the quality of product delivery and operational efficiency in edge computing scenarios, as well as increasing the resource utilization rate of edge nodes by 15%.",
        img: "",
      },
      {
        title: "Millisecond-level low latency, with delay <30ms",
        describe:
          "Quality nodes are deployed around the user terminals, covering all counties and cities nationwide as well as major carriers. Service content is distributed to the entire network's acceleration nodes, solving network congestion issues. This effectively increases the success rate of access and response speed, reduces transmission costs by 50%, and provides latency as low as milliseconds.",
        img: "",
      },
      {
        title: "Ultimate efficiency, with a 35% increase in scheduling rates",
        describe:
          "edge is designed based on the mainstream container engine K8S for non-standard heterogeneous edge computing networks. By using standard and open cloud-native technologies and capabilities, edge container orchestration is realized, increasing resource scheduling efficiency by 35%, achieving the ultimate cloud machine efficiency.",
        img: "",
      },
      {
        title: "Network-wide distribution, ultimate user experience",
        describe:
          "Supports all prefecture-level cities nationwide, various carrier network environments, and multiple platforms such as WEB / iOS / Android, offering network-wide distributed cloud service capabilities to handle high concurrency and ensure access quality.",
        img: "",
      },
      {
        title: "",
        describe: "",
        img: require("@/assets/images/document_img_01.png"),
      },
      {
        title: "7x24-hour technical support services",
        describe: "Comprehensive real-time data detection system for the entire link, with 7x24 technical support services, quickly responding to and resolving online issues.",
        img: "",
      },
    ],
  },
  // ICDN动态
  news: {
    list: [
      {
        title: "Building a global cloud high-speed network",
        time: "2023-08-01",
        id: "0",
        contents: "",
      },
      {
        title: "Overseas live streaming helps monetize",
        time: "2023-08-01",
        id: "1",
        contents: "",
      },
      {
        title: "Cloud Service 7.0 has started testing",
        time: "2023-08-01",
        id: "2",
        contents: "",
      },
      {
        title: "Global backbone network and ARM cluster servers",
        time: "2023-08-01",
        id: "3",
        contents: "",
      },
      {
        title: "Overseas data center nodes, supporting multiple countries",
        time: "2023-08-01",
        id: "4",
        contents: "",
      },
      {
        title: "Global backbone network and ARM cluster servers help you quickly",
        time: "2023-08-01",
        id: "5",
        contents: "",
      },
      {
        title: "ICDN: A Good Medicine for Video Websites to Reduce Costs and Increase Efficiency Overseas",
        time: "2023-08-01",
        id: "6",
        contents: `From 2005 to 2014, after experiencing multiple stages such as anti piracy, membership payment, and self-made content, domestic video websites gradually became clear about the market situation of "Youai Teng", with user growth continuing to decline, completing the transition from an incremental market to a stock market. According to data, the overall user growth rate of online video decreased to 5.7% in 2018, compared to 25.5% in 2008.
In addition to the decline in the growth rate of video websites, a large number of domestic internet products have also reached the growth ceiling, and new products have repeatedly failed. Both the stock market and the incremental market are in a chaotic situation, and no one is spared in the cold winter.
Therefore, in order to meet their own expansion and desire for new growth, seeking new markets overseas is almost a decision made by various platforms simultaneously.
In 2019, iQiyi launched an overseas version, and now its videos are watched by tens of millions of overseas users every month; Youku's overseas content also covers users from 193 countries and regions; The 2020 Tencent Video Annual Index Report shows that the number of WeTV users has increased by 175% compared to the previous year+`,
      },
    ],
  },
  newDetail: {
    title: `ICDN: A Good Medicine for Video Websites to Achieve Cost Reduction and Efficiency Enhancement Abroad - Interview with Zhu Hongbing, Founder of iCDN Telecom`,
    time: "2022-08-29",
    content: `
      <p>
        From 2005 to 2014, after the domestic video websites experienced anti-piracy, member payment, self-made content and other stages, the three-pillar market situation of "Youaitang" gradually became clear, the user growth rate continued to decline, and the transition from the incremental market to the stock market was completed. According to data, the overall user growth rate of online video dropped to 5.7% in 2018, compared with 25.5% in 2008.
      </p>
      <p>
        In addition to the decline in the growth rate of video websites, a large number of domestic Internet products have also touched the ceiling of growth, and new products have repeatedly died, whether the stock market or the incremental market is a free-for-all, in the winter, no one has been spared.
      </p>
      <p>
        Therefore, in order to satisfy their own expansion and the desire for new additions, going overseas to find new markets is almost a simultaneous decision made by each platform.
      </p>
      <p>
        In 2019, iQiyi launched its overseas version, and now tens of millions of overseas users watch its videos every month. Youku's content also covers users in 193 countries and regions; The "2020 Tencent Video Annual Index Report" shows that the number of WeTV users increased by 175% over the previous year, and the total viewing time increased by 300%, with users covering more than 110 countries and regions.
      </p>
      <p>
        In the context of the trend of head video websites going to sea, small and medium-sized video websites have further accelerated the pace. However, whether it is "love Youteng" or "small and medium-sized", they have to face a pain point - CDN problem in the process of going to sea for gold.
      </p>
      <p>
        For many video sites, CDN is too expensive, second only to film and television rights fees. Today, under the stimulation of 4K and even 8K video and 5G, the cost of CDN has risen exponentially, and many video platforms have been unable to bear the high cost of CDN.
      </p>
      <p>
        Fortunately, after a step by step exploration and running-in, a new generation of distributed data cache architecture platform PCDN came into being. iCDN has established the largest PCDN node cluster overseas.
      </p>
      <p>
        It is understood that iCDN uses low-cost edge heterogeneous fiber broadband to establish a machine cluster method, with PPIO developed cluster management software to manage each node, run PPIO, iQiyi and other PCDN nodes, to achieve cost-effective data transmission and caching capabilities, and then reduce costs. At the same time, the distributed nodes of iCDN can also solve the problems of low stability of the central node and easy to be sanctioned.
      </p>
      <p>
        Over the past few years, iCDN has served a number of large, small and medium-sized video sites going offshore, saving them about 50-70% of bandwidth costs. It can be said that the emergence of iCDN has become a good medicine for video websites to achieve overseas cost reduction and efficiency.
      </p>
      <p>
        Today, Xiou network reporter had the honor to interview Zhu Hongbing, founder of iCDN Telecom, more exciting content please see the interview transcript.
      </p>
      <p class="display-flex ">
       <img src="${require("@/assets/images/news_details_img_04.png")}"  />
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;">Xiou Network: Mr. Zhu, please introduce yourself, including your study and work experience.</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;">Zhu Hongbing：</lable>Hello readers of Xiou website, I'm Zhu Hongbing, founder of iCDN Telecom. In 1996, I graduated from the School of Mechanical and Electrical Engineering of Harbin Institute of Technology. When I just graduated, I was engaged in the research and development of the OCR derivative product "Hanwang Card Pass" in Beijing Hanwang Technology. In 2000, I moved to Singapore and joined i-DNS.net, an Internet domain name company, responsible for the development of a multilingual domain name system.
      </p>
      <p>
        I have been trying to start a business since 2003, and in the past ten years, I have founded Jixi Technology and Keyword Technology. Jisi Technology focuses on the development of machine translation and educational products, while Keyword Technology focuses on the release of advertisements for overseas Chinese people. At the same time, I have also participated in some angel investments, providing financial support to some companies that may be helpful to society.
      </p>
      <p>
        At the end of 2019, I founded iCDN Telecom, which is mainly engaged in edge cloud heterogeneous nodes, focusing on providing cheap and massive cloud transmission and cloud computing power for video companies and AI companies overseas. So far, we have provided nearly 400G of overseas transmission bandwidth for several video companies.
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;">Xiou Network: Your work experience is actually very rich, as a technician, founded an Internet technology company and also engaged in venture capital, what is the final opportunity for you to choose to enter the field of cloud transmission?</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Zhu Hongbing：</lable>The reason why we enter the field of cloud transmission is mainly to see the right entry point and commercial space.
      </p>
      <p>
          In 2019, many video websites want to go to sea to "rush for gold", but overseas CDN is basically monopolized by large European and American companies, such as Akamai, CloudFront, Google, etc., which is not only expensive, but also easy to be sanctioned for various reasons.
      </p>
      <p>
        I just understood their needs for going to sea and the pain points they faced, and found the opportunity to start in the cracks. After research, I entered the field of cloud transmission, providing bandwidth and cache node services for several video companies with overseas needs in Southeast Asia. Then we found a general service solution in this business that can be extended to other enterprises with similar needs, and provided to more enterprises in the region.
      </p>
       <p>
        <lable style=" color: #333; font-weight: bold;"> Xiou Network: At this stage, what pain points can iCDN Telecom solve for large and small video websites? In what scenarios is it mainly used?</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Zhu Hongbing：</lable>The first is the price pain point. The largest cost of the video industry is video transmission, after the increasing popularity of high-definition video, video transmission costs have become an increasing pressure in the industry. For large websites such as iQiyi and Youku, transmission costs account for about 15% to 22% of the entire company's expenses; For other small and medium-sized video sites, transmission costs account for more than 50% of the entire company's expenditure. Because we use the heterogeneous resources of small business rooms, we can usually compress the cost to about half, which greatly reduces the operating costs of video websites.
      </p>
      <p>
        Second, stability pain points. We are distributed nodes, the overall stability is better, and because the nodes are distributed all over the world, even if the network in one place is affected by the power supply or backbone network state, it can also schedule the node resources in other places in the second level to ensure the smooth operation of the video website.
      </p>
      <p>
        Finally, sanction pain points. Most of our nodes are placed in the computer room of small and micro enterprises, which will not be affected by the international environment, preventing customers from affecting overseas business because of sanctions. We are essentially a CDN business, providing accelerated nodes for video companies, and combining with customers' original CDNS to form cheap, stable, and powerful video transmission capabilities.
      </p>
      <p class="display-flex ">
        <img src="${require("@/assets/images/news_details_img_03.png")}"  />
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Xiou Network: What is the difference between iCDN base and iCDN plus?</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Zhu Hongbing：</lable>Both target different audiences.
      </p>
      <p>
       iCDN base is primarily for big video companies. They still use their own video delivery and node scheduling software, and we only provide more basic IaaS services. The reason is that large companies have strong technical strength and have the ability to develop their own dedicated video transmission and node scheduling software, and we play a supporting role in order to ensure its smooth operation. iCDN base generally provides more than 100 gigabytes of bandwidth, which can effectively reduce server and bandwidth costs by 30-50%.
      </p>
      <p>
        iCDN Plus is mainly offered to small and medium-sized video companies, who use our own video transmission and node scheduling software, and we provide a higher layer of PaaS services. Small companies generally do not have the technical strength to develop video transmission and node scheduling software, so they use our 302 jump or SDK to integrate into their APP to complete efficient video transmission. iCDN plus generally provides 10-100G bandwidth, which can effectively reduce the cost of server bandwidth by about 30% to 75%.
      </p>
       <p>
        <lable style=" color: #333; font-weight: bold;"> Xiou Network: What are iCDN Telecom's core competencies/differentiating advantages compared to its peers?</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Zhu Hongbing：</lable>First of all, compared with other peers, we go to sea earlier, more experienced, we will regularly do a comprehensive investigation and testing of the edge heterogeneous nodes in various countries, price, actual speed, stability and other issues.
      </p>
      <p>
        Secondly, the deployment and maintenance capabilities of our nodes are very strong, especially in Southeast Asian countries, we have self-built nodes, strong control capabilities, full port open, and can develop and deploy more than 100 Gb of distributed cache bandwidth nodes every month.
      </p>
      <p>
         At the same time, we can provide overall and feasible technical solutions and implementation plans according to the scale and specific requirements of video websites, effectively helping customers reduce unnecessary costs.
      </p>
      <p>
        Finally, in terms of service, we provide 7*24 hours of full time service to help customers solve various problems, so that customers can rest easy.
      </p>
       <p>
        <lable style=" color: #333; font-weight: bold;"> Xiou Network: In your opinion, what are the new changes in the development pattern of cloud transport in the future?</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Zhu Hongbing：</lable>With the rise of Web3.0, it is foreseeable that some Web3.0 companies will enter this field through subsidies, and as the market share increases, the cost will be further reduced. We will also become the node provider of Web3 system, and when the time window is right, we will also make deeper attempts to help customer enterprises develop better.
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Xiou Network: Please talk about iCDN Telecom's future plans and what you expect from iCDN Telecom.</lable>
      </p>
      <p>
        <lable style=" color: #333; font-weight: bold;"> Zhu Hongbing：</lable>In terms of cloud transmission, we still mainly focus on the development of nodes in the near future, especially expanding nodes in Southeast Asia and Japan, Korea and Taiwan to deepen service capabilities. We want to take advantage of the business needs of existing large companies, rent machines, establish home and small and medium-sized enterprise supply points in locations with low cost and large bandwidth, and continue to reduce prices, increase overall stability, meet the customization needs of large customers, and continue to meet the customer's growing demand for video caching and transmission overseas.
      </p>
      <p>
        In addition, we will continue to make efforts in the direction of cloud computing power, provide massive deep learning cheap computing power for biomedical enterprises, and develop new application scenarios of cheap computing power with the help of drug research and development as the starting point.
      </p>
    `,
  },
};
