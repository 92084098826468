"use strict";
function _typeof(e) {
  return (_typeof =
    "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
      ? function (e) {
          return typeof e;
        }
      : function (e) {
          return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
        })(e);
}
function _classCallCheck(e, t) {
  if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
}
function _defineProperties(e, t) {
  for (var n = 0; n < t.length; n++) {
    var i = t[n];
    (i.enumerable = i.enumerable || !1), (i.configurable = !0), "value" in i && (i.writable = !0), Object.defineProperty(e, _toPropertyKey(i.key), i);
  }
}
function _createClass(e, t, n) {
  return (
    t && _defineProperties(e.prototype, t),
    n && _defineProperties(e, n),
    Object.defineProperty(e, "prototype", {
      writable: !1,
    }),
    e
  );
}
function _toPropertyKey(e) {
  e = _toPrimitive(e, "string");
  return "symbol" === _typeof(e) ? e : String(e);
}
function _toPrimitive(e, t) {
  if ("object" !== _typeof(e) || null === e) return e;
  var n = e[Symbol.toPrimitive];
  if (void 0 === n) return ("string" === t ? String : Number)(e);
  n = n.call(e, t || "default");
  if ("object" !== _typeof(n)) return n;
  throw new TypeError("@@toPrimitive must return a primitive value.");
}
// var Slider = (function () {
//   function t(e) {
//     _classCallCheck(this, t),
//       (this.$el = e.el),
//       (this.$sliderContent = e.sliderContent || e.el),
//       void 0 === e.autoPlay ? (this.autoPlay = !0) : (this.autoPlay = e.autoPlay),
//       (this.canSlider = e.canSlider || !1),
//       (this.$page = this.$(".banner-item")),
//       (this.$btns = this.$(".banner-btn-sec .item")),
//       (this.timer = null),
//       (this.data = {
//         isOnbtn: !1,
//         len: this.$page.length,
//         dur: 8e3,
//       }),
//       (this.currentIndex = 0),
//       this.pChange(this.currentIndex),
//       this.bindEvent(),
//       this.canSlider && this.slide();
//   }
//   return (
//     _createClass(t, [
//       {
//         key: "$",
//         value: function (e) {
//           return this.$el.find(e);
//         },
//       },
//       {
//         key: "bindEvent",
//         value: function () {
//           var n = this;
//           this.data;
//           this.$btns.on("click", function (e) {
//             var e = $(e.currentTarget),
//               t = e.index();
//             (n.currentIndex = t), clearTimeout(n.timer), (n.data.isOnbtn = !0), e.addClass("banner-item-pause"), n.pChange(t);
//           }),
//             this.$btns.on("mouseleave", function (e) {
//               var e = $(e.currentTarget),
//                 t = e.index();
//               (n.currentIndex = t), n.data.isOnbtn && ((n.data.isOnbtn = !1), e.removeClass("banner-item-pause"), n.pChange(t));
//             });
//         },
//       },
//       {
//         key: "unBindEvent",
//         value: function () {
//           clearTimeout(this.timer);
//         },
//       },
//       {
//         key: "pChange",
//         value: function (t) {
//           var n = this;
//           this.$page.removeClass("show"),
//             this.$page.eq(t).addClass("show"),
//             this.$btns.removeClass("cur"),
//             this.$btns.eq(t).addClass("cur"),
//             this.data.isOnbtn ||
//               (this.autoPlay &&
//                 (this.timer = setTimeout(function () {
//                   var e = null,
//                     e = t >= n.data.len - 1 ? 0 : t + 1;
//                   n.pChange(e);
//                 }, this.data.dur)));
//         },
//       },
//       {
//         key: "slide",
//         value: function () {
//           var n = this,
//             t = this,
//             i = 0,
//             a = 0,
//             r = 0,
//             s = 0,
//             o = 0,
//             l = 0;
//           this.$el.on("touchstart", function (e) {
//             -1 == e.target.className.indexOf("case-btn") && -1 == e.target.className.indexOf("banner-btn-list-item")
//               ? ((i = e.originalEvent.changedTouches[0].pageX), (a = e.originalEvent.changedTouches[0].pageY))
//               : (l = o = s = r = a = i = 0);
//           }),
//             this.$el.on("touchend", function (e) {
//               -1 < e.target.className.indexOf("case-btn") || -1 < e.target.className.indexOf("banner-btn-list-item")
//                 ? (l = o = s = r = a = i = 0)
//                 : ((r = e.originalEvent.changedTouches[0].pageX),
//                   (s = e.originalEvent.changedTouches[0].pageY),
//                   (o = r - i),
//                   (l = s - a),
//                   Math.abs(o) > Math.abs(l) && 400 < o
//                     ? (0 == t.currentIndex ? (t.currentIndex = t.data.len - 1) : (t.currentIndex = t.currentIndex - 1), e.preventDefault(), t.pChange(t.currentIndex))
//                     : Math.abs(o) > Math.abs(l) && o < 400 && (e.preventDefault(), (t.currentIndex = (t.currentIndex + 1) % t.data.len), t.pChange(t.currentIndex)));
//             }),
//             this.$el.on("click", ".banner-btn-list-item", function (e) {
//               e.stopPropagation();
//               var e = $(e.currentTarget),
//                 t = e.index();
//               (n.currentIndex = t), (n.data.isOnbtn = !0), e.addClass("banner-item-pause"), n.pChange(t);
//             });
//         },
//       },
//     ]),
//     t
//   );
// })();

function opcityForAnimation(e, t, n) {
  var i = e.find(".animate-title"),
    e = e.find(".animate-content"),
    a = i.offset().top;
  a < n + t ? i.removeClass("animate-scroll-40-hidden").addClass("animate-scroll-visible") : i.removeClass("animate-scroll-visible").addClass("animate-scroll-40-hidden"),
    a + 130 < n + t ? e.removeClass("animate-scroll-40-hidden").addClass("animate-scroll-visible") : e.removeClass("animate-scroll-visible").addClass("animate-scroll-40-hidden");
}
$(function () {
  $(".more-link")
    .mouseover(function () {
      $(this).find(".scroll-right-icon img").css("animation", "iconMouseenter 0.3s linear 0s 1");
    })
    .mouseout(function () {
      $(this).find(".scroll-right-icon img").css("animation", "iconMouseleave 0.3s linear 0s 1"),
        setTimeout(function () {
          $(this).find(".scroll-right-icon img").removeAttr("style");
        }, 350);
    });
});
