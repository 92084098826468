export const formObj = {
  enter: "Enter",
  joinUsTitle: "Join us",
  joinUscompanyName: "Company name*",
  joinUsContacts: "contacts*",
  joinUsContactUs: "Contact Us*",
  joinUsIntroduce: "Company Or Team Introduction*",
  userName: "user name",
  password: "password",
  setPassword: "set password",
  iphone: "phone number",
  code: "6-digit SMS code",
  agreementRead: "I have read and agree",
  serviceAgreement: "User Service Agreement",
  lettersResponsibility: "Liability Statement on the Access Security for Information Source Network",
  privacyStatement: "Statement of Privacy Policies",
  countdown: "Resend code",
  formatError: "format error",
};
