export const btnObj = {
  all: "全部",
  joinUs: "加入我们",
  seeDetail: "查看详情",
  newUser: "企业新用户专享",
  learnMore: "了解更多",
  seeMore: "查看更多",
  submit: "提交",
  cancel: "取消",
  newEnterpriseUsers: "企业新用户",
  login: "登 录",
  register: "注 册",
  getCode: "获取验证码",
  signIn: "登录 ",
  newsDetail: "新闻资讯",
};
