export const formObj = {
  enter: "请输入",
  joinUsTitle: "加入我们",
  joinUscompanyName: "公司名称*",
  joinUsContacts: "联系人*",
  joinUsContactUs: "联系方式*",
  joinUsIntroduce: "公司或团队介绍*",
  userName: "用户名",
  password: "密码",
  setPassword: "设置密码",
  iphone: "手机号码",
  code: "6位短信验证码",
  agreementRead: "我已阅读并同意",
  serviceAgreement: "用户服务协议",
  lettersResponsibility: "信息源入网安全责任书",
  privacyStatement: "隐私政策声明",
  countdown: "秒后重新获取",
  formatError: "格式错误",
};
