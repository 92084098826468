export const titleObj = {
  joinUsImgTitle: "如何加入国际PCDN业务快速盈利",
  joinUsImgSubTitle: "不能错过的机会，现在就加入我们",
  productsTitle: "经济、高效、稳定的云产品",
  productsSubTitle: "生物数据处理，图像处理，视频处理，推荐算法",
  solutionTitle: "各大行业全栈解决方案",
  solutionSubTitle: "适用于游戏、直播、AI、视频、WEB 3.0等多行业解决方案",
  caseTitle: "成功案例",
  caseSubTitle: "拥有强大的技术背景和多次成功的商业经验的团队",
  stayTuned: "功能完善中 敬请期待···",
  newsTitle: "ICDN动态",
  newsSubTitle: "了解ICDN新闻最新动态，媒体报道，大事记",
  partnerTitle: "海外合作商",
  partnerSubTitle: "满足企业实际业务需求的定制化云计算服务",
  loginText: "ICDN账号登录",
  registerText: "欢迎注册ICDN",
  registerSubText: "已有账号？请",
  advantageTitle: "我们的优势",
  advantageSubTitle: "覆盖全球多个国家的运行节点",
  nodeName: {
    // 地图节点名称
    Singapore: "新加坡节点",
    Malaysia: "马来西亚节点",
    Thailand: "泰国节点",
    Indonesian: "印尼节点",
    HongKong: "香港节点",
    Taiwan: "台湾节点",
    Philippine: "菲律宾节点",
    Vietnam: "越南节点",
    Cambodia: "柬埔寨节点",
  },
};
