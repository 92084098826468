!(function () {
  var $kscIntroNew = $(".intro-new"),
    $kscProductTitle = $(".product").find(".animate-title"),
    $kscProductContent = $(".product").find(".animate-content"),
    scrollTop = $(window).scrollTop(),
    animationArray = (0 == scrollTop && ($kscProductTitle.addClass("product-title-animate"), $kscProductContent.addClass("product-content-animate")), []),
    dataAnimation = $("[data-animation]"),
    windowHeight =
      (dataAnimation.each(function (t, n) {
        n = $(n).attr("data-animation");
        n && animationArray.push(n);
      }),
      $(window).height());
  function anim(init) {
    var scrollTop = init ? 0 : $(window).scrollTop();
    animationArray.forEach(function (item) {
      eval(item + "(".concat(scrollTop, ",").concat(windowHeight, ")"));
    });
  }
  function throttle(i, a, c) {
    var r = new Date();
    return function () {
      var t = this,
        n = arguments,
        o = new Date();
      c <= o - r
        ? (i.apply(t, n), (r = o))
        : setTimeout(function () {
            i.apply(t, n);
          }, a);
    };
  }
  $(window).scroll(
    throttle(
      function () {
        anim();
      },
      50,
      100
    )
  );
})();
