export const titleObj = {
  joinUsImgTitle: "How to Join PCDN Services",
  joinUsImgSubTitle: "Make Quick Profits, Join Us Now",
  productsTitle: "Products & Services",
  productsSubTitle: "Biological data processing, image processing, video processing, recommendation algorithms",
  solutionTitle: "Solutions",
  solutionSubTitle: "Applications for Multiple Industries",
  caseTitle: "Success Cases",
  caseSubTitle: "A TEAM WITH STRONG TECHNOLOGY BACKGROUNDS AND MULTIPLE SUCCESSFUL BUSINESS EXPERIENCES",
  stayTuned: "Functionally improving,Stay tuned......",
  newsTitle: "ICDN News",
  newsSubTitle: "Stay updated on the latest developments in ICDN news",
  partnerTitle: "Partners",
  partnerSubTitle: "We sincerely invite partners from different industries to build cloud ecosystem together with us",
  loginText: "ICDN account login",
  registerText: "Welcome to register for ICDN",
  registerSubText: "Have an account? ",
  advantageTitle: "Our strengths",
  advantageSubTitle: "Operating nodes covering multiple countries worldwide",
  nodeName: {
    // 地图节点名称
    Singapore: "Singapore node",
    Malaysia: "Malaysia node",
    Thailand: "Thailand node",
    Indonesian: "Indonesian node",
    HongKong: "Hong Kong node",
    Taiwan: "Taiwan node",
    Philippine: "Philippine node",
    Vietnam: "Vietnam node",
    Cambodia: "Cambodia node",
  },
};
