export const btnObj = {
  all: "All",
  joinUs: "Join us",
  seeDetail: "View Detail",
  newUser: "Exclusive for new enterprise users",
  learnMore: "Learn more",
  seeMore: "More",
  submit: "Submit",
  cancel: "Cancel",
  newEnterpriseUsers: "New Enterprise Users",
  login: "Login",
  register: "Registration",
  getCode: "gain code",
  signIn: "Sign in",
  newsDetail: "News",
};
